/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./WidgetEditorDialog.vue?vue&type=template&id=00f33bf7&scoped=true&lang=pug&"
import script from "./WidgetEditorDialog.vue?vue&type=script&lang=ts&"
export * from "./WidgetEditorDialog.vue?vue&type=script&lang=ts&"
import style0 from "./WidgetEditorDialog.vue?vue&type=style&index=0&id=00f33bf7&scoped=true&lang=scss&"
import style1 from "./WidgetEditorDialog.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f33bf7",
  null
  
)

export default component.exports