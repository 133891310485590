"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var defaultConfig = {
  heading: 'Überschrift'
};
var component = (0, vue_1.defineComponent)({
  props: {
    widgetSettings: {
      type: Object,
      "default": function _default() {
        return defaultConfig;
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {},
  methods: {}
});
component.defaultConfig = defaultConfig;
exports["default"] = component;