"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var uiCommunication_1 = require("@/uiCommunication");

var vue_1 = require("vue");

var dashboardsStore_1 = require("@/state/dashboardsStore");

var teamsStore_1 = require("@/state/teamsStore");

var kpiStore_1 = require("@/state/kpiStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    widgetSettings: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    editMode: function editMode() {
      return dashboardsStore_1.dashboardsStore.getters.editMode;
    },
    kpiDefinition: function kpiDefinition() {
      var _a;

      var kpiKey = (_a = this.widgetSettings) === null || _a === void 0 ? void 0 : _a.kpiKey;

      if (kpiKey) {
        return kpiStore_1.kpiStore.getters.getKpiDefinition(kpiKey);
      }

      return undefined;
    },
    viewLink: function viewLink() {
      var _a, _b;

      var globalContext = dashboardsStore_1.dashboardsStore.getters.kpiGlobalContext; // override local context

      return (_b = (_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.getViewLinkToDetails) === null || _b === void 0 ? void 0 : _b.call(_a, globalContext);
    },
    behandlerFilter: function behandlerFilter() {
      var _this = this;

      var _a, _b;

      if (((_a = this.kpiDefinition) === null || _a === void 0 ? void 0 : _a.supportsBehandlerFilter) && this.widgetSettings.overrideTeam) {
        return (_b = teamsStore_1.teamsStore.getters.teams.find(function (t) {
          return t.id === _this.widgetSettings.overrideTeamId;
        })) === null || _b === void 0 ? void 0 : _b.name;
      }

      return undefined;
    },
    overrideDaterangeLabel: function overrideDaterangeLabel() {
      var dr = (0, dashboardsStore_1.getWidgetOverrideDaterange)(this.widgetSettings);
      return dr === null || dr === void 0 ? void 0 : dr.label;
    }
  },
  methods: {
    openLinkInUi: function openLinkInUi(link) {
      (0, uiCommunication_1.openLinkInUi)(link);
    }
  }
});