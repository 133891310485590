"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getViewSpecificBehandlerAccess = exports.login = void 0;
const data_1 = require("./data");
const authStore_1 = require("@/state/authStore");
const src_1 = require("@/../../base/src");
const login = async (creds) => {
    const res = await data_1.metricsApi.auth.login(creds);
    if (!validateUserObject(res)) {
        throw new Error('login failed, message: ' + res.message);
    }
    const user = {
        email: res.email,
        cid: res.mainclientid,
        token: res.token,
        firstName: res.firstName,
        lastName: res.lastName,
    };
    return user;
};
exports.login = login;
function validateUserObject(user) {
    return user.cid && user.email && user.token;
}
function getViewSpecificBehandlerAccess(viewId, teams) {
    const user = authStore_1.authStore.getters.user;
    return (0, src_1.getViewSpecificBehandlerAccessMembers)({
        viewId,
        viewSpecificBehandlerAccess: user === null || user === void 0 ? void 0 : user.viewSpecificBehandlerAccess,
        teams,
    });
}
exports.getViewSpecificBehandlerAccess = getViewSpecificBehandlerAccess;
