"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var v_tooltip_1 = require("v-tooltip");

var kpis = [{
  name: 'Umsatz',
  value: '412€'
}, {
  name: 'Neupatienten',
  value: '32'
}, {
  name: 'Score',
  value: '74%'
}];
var defaultConfig = {
  kpiKey: 'Neupatienten',
  color: '#9797f6'
};
var component = (0, vue_1.defineComponent)({
  props: {
    widgetSettings: {
      type: Object,
      "default": function _default() {
        return defaultConfig;
      }
    }
  },
  components: {
    'v-popper': v_tooltip_1.VPopover
  },
  data: function data() {
    return {
      kpis: kpis,
      msg: 0
    };
  },
  computed: {
    kpi: function kpi() {
      var _this = this;

      var _a;

      return (_a = kpis.find(function (kpi) {
        return kpi.name === _this.widgetSettings.kpiKey;
      })) !== null && _a !== void 0 ? _a : kpis[0];
    }
  },
  mounted: function mounted() {},
  methods: {}
});
component.defaultConfig = defaultConfig;
exports["default"] = component;